<template>
  <div ref="$wrapper" class="slider-list grid grid-cols-1 lg:grid-cols-12">
    <div class="lg:col-span-5 hidden col-span-2 min-h-[58vh] lg:flex lg:items-end">
      <Splide
        ref="$splide"
        :has-track="true"
        :options="splideConfig"
        @splide:move="onMove"
      >
        <SplideSlide
          v-for="(slide, idx) in slides"
          :key="idx"
        >
          <img
            :src="slide.imageSrc"
            :alt="slide.label"
            class="aspect-4/5 h-[25rem]"
            loading="lazy"
          >
        </SplideSlide>
      </Splide>
    </div>

    <div class="lg:col-span-7 col-span-full self-start">
      <BaseAccordion
        class="flex flex-col gap-4"
        :active-index="activeItem"
      >
        <BaseAccordionItem
          v-for="(item, index) in slides"
          :key="index"
          :chevron="false"
          class="cursor-pointer transition-all duration-300 ease-in-out"
          borderless
          @click="goToSlide(index)"
        >
          <template #trigger>
            <div class="flex items-end">
              <div class="text-sm mr-2">
                0{{ index +1 }}
              </div>
              <div class="text-4xl font-semibold">
                {{ item.label }}
              </div>
            </div>
          </template>
          <div class="pt-6 ml-6">
            <BaseParagraph :data="item.body" />

            <div class="flex lg:hidden">
              <img
                :src="item.imageSrc"
                :alt="item.label"
                class="aspect-4/5 h-48"
                loading="lazy"
              >
            </div>
          </div>
        </BaseAccordionItem>
      </BaseAccordion>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SplideSlide, Splide } from '@splidejs/vue-splide';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { BaseAccordion, BaseAccordionItem } from '../accordion';
import type { SliderItem } from '~/types';
import BaseParagraph from '~/components/BaseParagraph.vue';

interface Props {
  slides: SliderItem[]
}
defineProps<Props>();

const $splide = ref(null) as any;
const $wrapper = ref(null) as any;
const activeItem = ref(0);
const shouldChangeActiveItem = ref(true);
const splideConfig = {
  perPage: 1,
  type: 'fade',
  arrows: false,
  pagination: false,
  autoplay: true,
  rewind: true,
};

function goToSlide (index: number) {
  $splide.value.go(index);
}

function onMove (_: unknown, activeIndex: number) {
  if (shouldChangeActiveItem.value) {
    activeItem.value = Number(activeIndex);
  }
}

let intersectionObserver: any = null;

onMounted(() => {
  intersectionObserver = new IntersectionObserver((entries) => {
    const [entry] = entries;
    shouldChangeActiveItem.value = entry.isIntersecting;
  }, {
    root: null,
    rootMargin: '4px',
    threshold: 0.1,
  });
  if ($wrapper.value) {
    intersectionObserver.observe($wrapper.value);
  }
});

onBeforeUnmount(() => {
  if ($wrapper.value && intersectionObserver) {
    intersectionObserver.unobserve($wrapper.value);
  }
});
</script>
